import Vue from "vue";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    LoadingSpinner,
  },
  props: {},
  data: () => ({
    loadingSpinner: Vue.observable({
      counter: 0,
    }),
  }),
  provide() {
    return {
      loadingSpinner: this.loadingSpinner,
    };
  },
  methods: {},
};
