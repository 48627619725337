<template>
  <div class="d-flex">
    <v-text-field
      v-model="internalValue.from"
      class="txt-single date-style"
      :readonly="readonly"
      :disabled="disabled"
      :label="label + '（From）'"
      :rules="required ? [vRules.requiredDateRule, ...rules] : [vRules.dateRule, ...rules]"
      :clearable="!readonly"
      :clear-icon="readonly || disabled ? '' : 'mdi-close-circle'"
      @change="changeDate('from')"
      outlined
      dense
    />
    <span class="require asterisk-spacer" v-if="required">*</span>
    <div class="calender-btn-area">
      <v-menu
        v-model="fromDatePickerDisplayFlg"
        :close-on-content-click="false"
        :nudge-right="-100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="date-calendar"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text x-small :disabled="readonly || disabled">
            <v-icon class="icon-calendar">mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="fromDatePickerValue"
          @input="fromDatePickerDisplayFlg = false"
        ></v-date-picker>
      </v-menu>
      <v-btn
        id="btn-yesterday"
        class="day-ctrl-btn"
        @click="prevDate('from')"
        text
        x-small
        :disabled="readonly || disabled"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        id="btn-tomorow"
        class="day-ctrl-btn"
        @click="nextDate('from')"
        text
        x-small
        :disabled="readonly || disabled"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <!-- カレンダーの間 -->
    <div class="from_to">～</div>

    <v-text-field
      v-model="internalValue.to"
      class="txt-single date-style ml-3"
      :readonly="readonly"
      :disabled="disabled"
      :label="label + '（to）'"
      :rules="
        required
          ? [
              vRules.requiredDateRule,
              vRules.isFutureDate(internalValue.to, internalValue.from, label + '（From）'),
              ...rules,
            ]
          : [
              vRules.dateRule,
              vRules.isFutureDate(internalValue.to, internalValue.from, label + '（From）'),
              ...rules,
            ]
      "
      :clearable="!readonly"
      :clear-icon="readonly || disabled ? '' : 'mdi-close-circle'"
      @change="changeDate('to')"
      outlined
      dense
    />
    <span class="require asterisk-spacer" v-if="required">*</span>
    <div class="calender-btn-area">
      <v-menu
        v-model="toDatePickerDisplayFlg"
        :close-on-content-click="false"
        :nudge-right="-100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="date-calendar"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text x-small :disabled="readonly || disabled">
            <v-icon class="icon-calendar">mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="toDatePickerValue"
          @input="toDatePickerDisplayFlg = false"
        ></v-date-picker>
      </v-menu>
      <v-btn
        id="btn-yesterday"
        class="day-ctrl-btn"
        @click="prevDate('to')"
        text
        x-small
        :disabled="readonly || disabled"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        id="btn-tomorow"
        class="day-ctrl-btn"
        @click="nextDate('to')"
        text
        x-small
        :disabled="readonly || disabled"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";

export default {
  inheritAttrs: false,
  props: {
    // 値
    value: {
      type: Object,
      required: true,
      default: () => ({ from: "", to: "" }),
    },
    // ラベル
    label: {
      type: String,
      required: true,
      default: "",
    },
    // 必須
    required: {
      type: Boolean,
      default: false,
    },
    // 読み取り専用
    readonly: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    // 値
    internalValue: { from: "", to: "" },
    // カレンダー値
    fromDatePickerValue: "",
    toDatePickerValue: "",
    // カレンダー表示フラグ
    fromDatePickerDisplayFlg: false,
    toDatePickerDisplayFlg: false,
    // バリデーション
    vRules: {
      requiredDateRule: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      dateRule: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 未来日チェック
      isFutureDate: (toValue, fromValue, param) => {
        if (
          !!toValue &&
          !!fromValue &&
          !!param &&
          dateTimeHelper.validDate(commonUtil.zen2han(toValue), "/") &&
          dateTimeHelper.isDate(toValue) &&
          dateTimeHelper.validDate(commonUtil.zen2han(fromValue), "/") &&
          dateTimeHelper.isDate(fromValue) &&
          new Date(toValue).getTime() < new Date(fromValue).getTime()
        ) {
          return i18n.tc("check.chk_inputFutureDate2", 0, [param]);
        }
        return true;
      },
    },
  }),
  methods: {
    // 初期化
    init() {
      // 初期値が存在する場合
      this.internalValue.from = this.changeDate(this.value.from);
      if (this.isValidDateFormat(this.internalValue.from)) {
        this.fromDatePickerValue = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST(this.internalValue.from)
        );
      }
      this.internalValue.to = this.changeDate(this.value.to);
      if (this.isValidDateFormat(this.internalValue.to)) {
        this.toDatePickerValue = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST(this.internalValue.to)
        );
      }
    },
    // 基準日を－１日します。
    prevDate(type) {
      if (!this[type + "DatePickerValue"]) return;
      let date = new Date(this[type + "DatePickerValue"]);
      this[type + "DatePickerValue"] = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    // 基準日を＋１日します。
    nextDate(type) {
      if (!this[type + "DatePickerValue"]) return;
      let date = new Date(this[type + "DatePickerValue"]);
      this[type + "DatePickerValue"] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    // yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
    formatDate(date) {
      if (!date) return null;
      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力処理
    changeDate(newVal) {
      // 全角 → 半角
      const toHalfWidth = (str) =>
        str.replace(/[０-９]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xfee0));
      // 半角
      const normalizedInput = toHalfWidth(newVal);
      // 正規表現
      const regexCompact = /^\d{8}$/; // yyyymmdd
      const regexSpaced = /^\d{4}\s\d{2}\s\d{2}$/; // yyyy mm dd
      // 判定
      if (regexCompact.test(normalizedInput)) {
        // yyyymmdd → yyyy/mm/dd
        const year = normalizedInput.slice(0, 4);
        const month = normalizedInput.slice(4, 6);
        const day = normalizedInput.slice(6, 8);
        return `${year}/${month}/${day}`;
      } else if (regexSpaced.test(normalizedInput)) {
        // yyyy mm dd → yyyy/mm/dd
        const [year, month, day] = normalizedInput.split(" ");
        return `${year}/${month}/${day}`;
      }
      // 一致しない場合、元の値を返却
      return newVal;
    },
    // 日付存在チェック
    isValidDateFormat(dateStr) {
      // 正規表現：yyyy/MM/dd, yyyy-MM-dd
      const regex = /^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[1-2][0-9]|3[0-1])$/;
      if (!regex.test(dateStr)) return false;
      const [year, month, day] = dateStr.split(/[-/]/).map(Number);
      const date = new Date(year, month - 1, day);
      return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    },
  },
  watch: {
    fromDatePickerValue(newValue) {
      if (this.isValidDateFormat(newValue)) {
        this.internalValue.from = this.formatDate(newValue);
      }
    },
    toDatePickerValue(newValue) {
      if (this.isValidDateFormat(newValue)) {
        this.internalValue.to = this.formatDate(newValue);
      }
    },
    value: {
      handler(newValue) {
        this.internalValue.from = this.changeDate(newValue.from);
        if (this.isValidDateFormat(this.internalValue.from)) {
          // 日付の場合
          this.fromDatePickerValue = dateTimeHelper.dateCalc(
            dateTimeHelper.convertJST(this.internalValue.from)
          );
        } else {
          // 日付ではない場合、初期化
          this.fromDatePickerValue = "";
        }

        this.internalValue.to = this.changeDate(newValue.to);
        if (this.isValidDateFormat(this.internalValue.to)) {
          // 日付の場合
          this.toDatePickerValue = dateTimeHelper.dateCalc(
            dateTimeHelper.convertJST(this.internalValue.to)
          );
        } else {
          // 日付ではない場合、初期化
          this.toDatePickerValue = "";
        }
      },
      deep: true,
      immediate: true,
    },
    internalValue: {
      handler(newValue) {
        this.$emit("input", { ...newValue });
      },
      deep: true,
    },
  },
  mounted() {},
  computed: {},
  created() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
