<template>
  <v-dialog
    :value="errorMessage.isError"
    :max-width="800"
    persistent
    no-click-animation
    @click:outside="ok"
  >
    <v-card>
      <v-card-title class="blue-grey lighten-3" primary-title>{{
        $t("label.lbl_result")
      }}</v-card-title>
      <v-card-text class="pa-4">
        <p style="white-space: pre-wrap">{{ errorMessage.message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="ok">{{ $t("btn.btn_ok_en") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorMessageDialog",
  props: {},
  inject: ["errorMessage"],
  methods: {
    ok() {
      this.errorMessage.isError = false;
      this.errorMessage.message = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}

span {
  color: red;
}
</style>
