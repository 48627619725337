<template>
  <div :style="width ? { width: width } : {}">
    <v-tooltip bottom :disabled="!isTooltipFlg">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex" v-bind="attrs" v-on="on">
          <v-autocomplete
            v-if="!isToggleBtn"
            ref="textElement"
            v-model="selectedItem"
            :items="internalItems"
            :hint="commonUtil.findPropertyByValue(internalItems, selectedItem)"
            :label="getLabel()"
            :readonly="readonly"
            :disabled="disabled"
            :append-icon="'$dropdown'"
            :rules="required ? [vRules.inputRequired, ...rules] : [...rules]"
            item-value="value"
            item-text="text"
            persistent-hint
            dense
          >
            <template slot="item" slot-scope="data">
              <span class="auto-txt-size"> {{ data.item.text }} </span></template
            >
          </v-autocomplete>
          <v-btn-toggle
            v-else
            v-model="selectedItem"
            color="blue darken-2"
            class="toggle black--text"
          >
            <v-btn
              mandatory
              class="res-btn"
              v-for="item in internalItems"
              :key="item.value"
              :value="item.value"
              small
              :disabled="disabled"
            >
              {{ item.text }}</v-btn
            >
          </v-btn-toggle>
          <span class="require asterisk-spacer" v-if="!isToggleBtn && required">*</span>
        </div>
      </template>
      <span
        >{{ commonUtil.findPropertyByValue(internalItems, selectedItem, "value", "text") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { getParameter } from "@/assets/scripts/js/GetParameter";

export default {
  inheritAttrs: false,
  components: {},
  props: {
    // 値
    value: {
      type: [String, Number],
      default: "",
    },
    // コードタイプ
    codeType: {
      type: String,
      default: undefined,
      required: true,
    },
    // 必須
    required: {
      type: Boolean,
      default: false,
    },
    // 読み取り専用
    readonly: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // トグルボタン
    isToggleBtn: {
      type: Boolean,
      default: false,
    },
    // 幅
    width: {
      type: String,
      default: undefined,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["errorMessage", "loadingSpinner"],
  data: () => ({
    // 選択値
    selectedItem: "",
    // リスト
    internalItems: [],
    // ツールチップフラグ
    isTooltipFlg: false,
    // 共通機能
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
    },
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
  }),
  methods: {
    init() {
      if (this.codeType) {
        // ローディング画面表示ON
        this.loadingSpinner.counter++;
        // 初期値設定
        if (this.value) {
          this.selectedItem = this.value;
        }
        // コードマスタ
        const codeList = getParameter.getCodeMst(this.codeType);
        Promise.all([codeList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.internalItems = result[0];
            // トグルボタンの場合、自動選択
            if (this.isToggleBtn) {
              this.selectedItem = this.internalItems[0].value;
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      }
    },
    getLabel() {
      if (this.codeType == appConfig.CODETYPE.IN_PROCESS_DIV) {
        // 処理区分（0101）
        return i18n.tc("label.lbl_processingdivision");
      } else if (this.codeType == appConfig.CODETYPE.IN_QUALITY_DIV) {
        // 品質区分（0102）
        return i18n.tc("label.lbl_arrivalStatus");
      } else if (this.codeType == appConfig.CODETYPE.IN_BLAME_DIV) {
        // 責任区分（0103）
        return i18n.tc("label.lbl_arrivalStatus");
      } else if (this.codeType == appConfig.CODETYPE.IN_FREECOST_DIV) {
        // 有償/無償（0104）
        return i18n.tc("label.lbl_isPaid");
      } else if (this.codeType == appConfig.CODETYPE.IN_REASON_DIV) {
        // 無償理由（0105）、理由コード
        return i18n.tc("label.freeReasonList");
      } else if (this.codeType == appConfig.CODETYPE.IN_ENTRY_DIV) {
        // 入荷登録区分（0107）
        // 利用していない
        return "";
      } else if (this.codeType == appConfig.CODETYPE.ACHIEVE_CONFIRM) {
        // 実績登録有無（0109）
        // 利用していない
        return "";
      } else if (this.codeType == appConfig.CODETYPE.COMPARISON_RESULT) {
        // 比較結果（0110）
        // 利用していない
        return "";
      } else if (this.codeType == appConfig.CODETYPE.ALLOCATION_STANDARD) {
        // 引当基準（0201）
        return i18n.tc("label.lbl_reserveBasis");
      } else if (this.codeType == appConfig.CODETYPE.PICKING_TYPE) {
        // ピッキング（0202）
        return i18n.tc("label.lbl_picking");
      } else if (this.codeType == appConfig.CODETYPE.STATUS_TYPE) {
        // 在庫ステータス（0203）
        return i18n.tc("label.lbl_status");
      } else if (this.codeType == appConfig.CODETYPE.TRANSITION_TYPE) {
        // 在庫推移（0204）
        // 呼ばれる個所は確認できるが、利用していない
        return "";
      } else if (this.codeType == appConfig.CODETYPE.TRANSACTION_TYPE) {
        // 推移ステータス（0204）
        return i18n.tc("label.lbl_transition");
      } else if (this.codeType == appConfig.CODETYPE.DELIVERY_TYPE_DIV) {
        // 納品種別
        // 納品種別のラベル
        return i18n.tc("label.lbl_deliveryType");
      } else if (this.codeType == appConfig.CODETYPE.OUT_REQUEST_DIV) {
        // 出庫依頼有無
        // 出庫依頼有無のラベル
        return i18n.tc("label.lbl_outBoundRequest");
      } else if (this.codeType == appConfig.CODETYPE.MONETARY_UNIT_DIV) {
        // 通貨単位
        // 通貨単位のラベル
        return i18n.tc("label.lbl_monetaryUnitDiv");
      } else {
        return "";
      }
    },
  },
  watch: {
    value(newValue) {
      this.selectedItem = newValue;
      this.$nextTick(() => {
        setTimeout(() => {
          // 選択取引先名が入力幅を超えたらツールチップを表示
          const textElement = this.$refs.textElement.$el.querySelector(".v-input__control input");
          this.isTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
        }, 0);
      });
    },
    selectedItem(newValue) {
      this.$emit("input", newValue);
    },
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
