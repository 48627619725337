<template>
  <div :style="width ? { width: width } : {}">
    <v-tooltip bottom :disabled="!isTooltipFlg">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex" v-bind="attrs" v-on="on">
          <v-autocomplete
            ref="textElement"
            v-model="selectedItem"
            :items="internalItems"
            :search-input.sync="search"
            :hint="commonUtil.findPropertyByValue(internalItems, selectedItem)"
            :label="$t('label.lbl_belong')"
            :readonly="readonly"
            :disabled="disabled"
            :append-icon="'$dropdown'"
            :rules="required ? [vRules.inputRequired, ...rules] : [...rules]"
            item-value="value"
            item-text="text"
            persistent-hint
            dense
          >
            <template slot="item" slot-scope="data">
              <span class="auto-txt-size"> {{ data.item.text }} </span></template
            >
          </v-autocomplete>
          <span class="require asterisk-spacer" v-if="required">*</span>
        </div>
      </template>
      <span
        >{{ commonUtil.findPropertyByValue(internalItems, selectedItem, "value", "text") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { getParameter } from "@/assets/scripts/js/GetParameter";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";

export default {
  inheritAttrs: false,
  components: {},
  props: {
    // 値
    value: {
      type: [String, Number],
      default: "",
    },
    // 必須
    required: {
      type: Boolean,
      default: false,
    },
    // 読み取り専用
    readonly: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // 幅
    width: {
      type: String,
      default: undefined,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["errorMessage", "loadingSpinner"],
  data: () => ({
    // 選択値
    selectedItem: "",
    // リスト
    internalItems: [],
    // ツールチップフラグ
    isTooltipFlg: false,
    // 検索
    search: "",
    // 共通機能
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
    },
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
  }),
  methods: {
    // 初期処理
    init() {
      // 初期値が存在する場合
      if (this.value) {
        // 初期値設定
        this.selectedItem = this.value;
        // 所属検索
        this.searchItems({
          searchKbn: "1",
          belongSid: this.value,
          referenceDate: dateTimeHelper.convertUTC(),
        });
      }
    },
    // 所属検索
    searchItems(params) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;

      // 所属
      const belongList = getParameter.getBelongMst(params);
      Promise.all([belongList])
        .then((result) => {
          // リスト設定
          this.internalItems = result[0];
        })
        .catch((ex) => {
          if (!this.errorMessage.isError) {
            this.errorMessage.isError = true;
            this.errorMessage.message = ex;
          }
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingSpinner.counter--;
        });
    },
  },
  watch: {
    value(newValue) {
      this.selectedItem = newValue;
      if (!newValue) {
        this.internalItems = [];
      }
      this.$nextTick(() => {
        setTimeout(() => {
          // 入力幅を超えたらツールチップを表示
          const textElement = this.$refs.textElement.$el.querySelector(".v-input__control input");
          this.isTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
        }, 0);
      });
    },
    selectedItem(newValue) {
      this.$emit("input", newValue);
    },
    search(newValue) {
      // 検索内容が存在する場合
      if (newValue ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (
          newValue ==
          this.commonUtil.findPropertyByValue(
            this.internalItems,
            this.selectedItem,
            "value",
            "text"
          )
        ) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (newValue.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (newValue.length >= appConfig.CNTCHARITEM_2) {
          // 所属検索
          this.searchItems({
            searchKbn: "3",
            officialName: newValue,
            referenceDate: dateTimeHelper.convertUTC(),
          });
        } else {
          this.internalItems = [];
        }
      }
    },
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
