<template>
  <v-dialog
    :value="isShow"
    :max-width="800"
    persistent
    no-click-animation
    @click:outside="ok(true)"
  >
    <v-card>
      <v-card-title class="blue-grey lighten-3" primary-title>{{ title }}</v-card-title>

      <v-card-text class="pa-4">
        <p style="white-space: pre-wrap">{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- OKボタン（とじる処理のみ） -->
        <v-btn v-if="firstPageFlag" @click="ok(false)"> OK </v-btn>
        <!-- 閉じるボタン（outsideClickNotCloseFlgに影響されない） -->
        <v-btn v-if="closeFlag" @click="close"> 閉じる </v-btn>
        <!-- ホーム画面（ホーム画面遷移） -->
        <v-btn v-if="homePageFlag" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <!-- 出荷予定一覧画面 -->
        <v-btn v-if="shpSchListFlg" @click="movePage('P_SHP_003')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 出荷予定登録 -->
        <v-btn v-if="shpSchAddFlg" @click="movePage('P_SHP_001')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- 入荷予定一覧画面 -->
        <v-btn v-if="rcvSchListFlg" @click="movePage('P_RCV_003')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 入荷予定一覧画面(試作部品) -->
        <v-btn v-if="rcvSchHacListFlg" @click="movePage('P_RCV_102')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 入荷予定データ取込画面 -->
        <v-btn v-if="rcvDetaImportFlg" @click="movePage('P_RCV_002')">
          {{ $t("btn.btn_diffComparison") }}
        </v-btn>
        <!-- 入荷予定登録画面 -->
        <v-btn v-if="rcvSchAddFlg" @click="movePage('P_RCV_001')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- 入庫実績登録（検索）画面 -->
        <v-btn v-if="entAchSerchFlg" @click="movePage('P_ENT_001')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 在庫引当画面（入力画面） -->
        <v-btn v-if="InvResFlg" @click="movePage('P_STK_001')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- 在庫引当画面（在庫引当） -->
        <v-btn v-if="InvResTransitionFlg" @click="movePage('P_STK_001')">
          {{ $t("btn.btn_inventoryReservation") }}
        </v-btn>
        <!-- 加工計画一覧画面 -->
        <v-btn v-if="dstSchListFlg" @click="movePage('P_TMP_903')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 引当結果確認画面 -->
        <v-btn v-if="resConFlg" @click="movePage('P_STK_002')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- はい替え実績一覧 -->
        <v-btn v-if="movLocListFlg" @click="movePage('P_MOV_003')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- はい替え実績登録（指示なし） -->
        <v-btn v-if="movLocAchAddFlg" @click="movePage('P_MOV_004')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- はい替え実績登録（指示あり） -->
        <v-btn v-if="movLocAchAddInsFlg" @click="movePage('P_MOV_005')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- 予定実績対比 -->
        <v-btn v-if="mngComSchFlg" @click="movePage('P_MNG_001')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 受注一覧画面 -->
        <v-btn v-if="trnListFlg" @click="movePage('P_TOD_002')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 受注登録 -->
        <v-btn v-if="trnAddFlg" @click="movePage('P_TOD_001')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- ↓個別で設定した。今後削除予定 -->
        <!-- ここからはい替え指示登録時に使用 -->
        <v-btn v-if="forthPageFlag" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="forthPageFlag" @click="movePage('P_MOV_003')">
          {{ $t("label.lbl_moveLocationList") }}
        </v-btn>
        <v-btn v-if="forthPageFlag" @click="movePage('P_MOV_001')">
          {{ $t("label.lbl_moveLocationAdd") }}
        </v-btn>
        <!-- ここまではい替え指示登録時に使用 -->
        <v-btn v-if="fivePageFlag" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="fivePageFlag" @click="movePage('P_RCV_ACTUAL_LIST')">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- ここから棚卸計画登録時に使用 -->
        <v-btn v-if="sixPageFlag" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="sixPageFlag" @click="movePage('P_CNT_003')">
          {{ $t("label.lbl_countPlanList") }}
        </v-btn>
        <v-btn v-if="sixPageFlag" @click="movePage('P_CNT_001')">
          {{ $t("label.lbl_countInstSearch") }}
        </v-btn>
        <v-btn v-if="countInstSearchFlag" @click="movePage('P_CNT_001', countInstSearchPageParams)">
          OK
        </v-btn>
        <!-- ここまで棚卸計画登録時に使用 -->
        <!-- 関係先マスタ登録時に使用 -->
        <v-btn v-if="relatedMstFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="relatedMstFlg" @click="movePage('P_MST_018')">
          {{ $t("label.lbl_relatedPartiesList") }}
        </v-btn>
        <!-- 関係先マスタ登録時に使用 -->
        <!-- ここから倉庫マスタ登録時に使用 -->
        <v-btn v-if="sevenPageFlag" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="sevenPageFlag" @click="movePage('P_MST_002')">
          {{ $t("label.lbl_warehouseMasterList") }}
        </v-btn>
        <!-- ここまで倉庫マスタ登録時に使用 -->
        <!-- ↑ここまで削除予定 -->
        <!-- ここから商品マスタ登録時に使用 -->
        <v-btn v-if="itemsMstFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="itemsMstFlg" @click="movePage('P_MST_007')">
          {{ $t("label.lbl_itemMastaList") }}
        </v-btn>
        <!-- ここから所属マスタ登録時に使用 -->
        <v-btn v-if="belongMstFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="belongMstFlg" @click="movePage('P_MST_020')">
          {{ $t("label.lbl_affiliationList") }}
        </v-btn>
        <!-- ここからロケマスタ登録時に使用 -->
        <v-btn v-if="locaMstFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="locaMstFlg" @click="movePage('P_MST_005')">
          {{ $t("label.lbl_locationMasterList") }}
        </v-btn>
        <!-- ここから棚卸実績承認時に使用 -->
        <v-btn v-if="cntapprovalFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="cntapprovalFlg" @click="movePage('P_CNT_003')">
          {{ $t("label.lbl_countPlanList") }}
        </v-btn>
        <v-btn v-if="cntapprovalFlg" @click="movePage('P_CNT_001')">
          {{ $t("label.lbl_countInstSearch") }}
        </v-btn>
        <!-- ここから返品伝票承認時に使用 -->
        <v-btn v-if="rtnapprovalFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="rtnapprovalFlg" @click="movePage('P_RTN_002')">
          {{ $t("返品伝票一覧") }}
        </v-btn>
        <v-btn v-if="rtnapprovalFlg" @click="movePage('P_RTN_001')">
          {{ $t("返品伝票登録") }}
        </v-btn>
        <!-- ここから返品伝票登録に使用 -->
        <v-btn v-if="rtnAddFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="rtnAddFlg" @click="movePage('P_RTN_002')">
          {{ $t("返品伝票一覧") }}
        </v-btn>
        <!-- ここから請求契約単価登録変更に使用 -->
        <v-btn v-if="bilCotractUnitPriceRegistrationFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="bilCotractUnitPriceRegistrationFlg" @click="movePage('P_BIL_001')">
          {{ $t("label.lbl_billingContractList") }}
        </v-btn>
        <v-btn v-if="bilCotractUnitPriceRegistrationFlg" @click="movePage('P_BIL_004')">
          {{ $t("label.lbl_billingContractUnitPriceRegistration") }}
        </v-btn>
        <!-- ここから請求実績登録時（新規登録）に使用 -->
        <v-btn v-if="bilActualFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="bilActualFlg" @click="movePage('P_BIL_002')">
          {{ $t("label.lbl_billingAchievementList") }}
        </v-btn>
        <!-- ここからフォーマット別追加情報登録時に使用 -->
        <v-btn v-if="shpAddFormatFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="shpAddFormatFlg" @click="movePage('P_SHP_005')">
          {{ $t("label.lbl_routeSlip") }}
        </v-btn>
        <v-btn
          v-if="addMoveListPageButtonText != ''"
          @click="movePage(addModeListPage, addModeListPageParams)"
        >
          {{ addMoveListPageButtonText }}
        </v-btn>
        <v-btn v-if="addMovePageButtonText != ''" @click="movePage(addModePage, addModePageParams)">
          {{ addMovePageButtonText }}
        </v-btn>
        <!-- ここから在庫情報変更指示登録・更新・削除時に使用 -->
        <v-btn v-if="stockUpdateInstructionFlg" @click="movePage('P_STC_001')">
          {{ $t("label.lbl_stockUpdateInstructionList") }}
        </v-btn>
        <v-btn v-if="stockInstructionAddFlg" @click="movePage('P_STC_003')">
          {{ $t("label.lbl_stockInstructionAdd") }}
        </v-btn>
        <v-btn v-if="stockInstructionAddFlg" @click="movePage('P_STC_001')">
          {{ $t("label.lbl_stockUpdateInstructionList") }}
        </v-btn>
        <!-- ここから在庫情報変更データ取込時に使用 -->
        <v-btn v-if="stockInformationChangeDataFlg" @click="movePage('P_STK_103')">
          {{ $t("label.lbl_stockInquiry") }}
        </v-btn>
        <!-- ここからマスタ一括取込登録時に使用 -->
        <v-btn v-if="roleMasterBatchAddUpdateFlg" @click="movePage('P_MST_016', roleMasterParams)">
          {{ $t("label.lbl_roleMasterBatchAddUpdate") }}
        </v-btn>
        <v-btn v-if="iniMasterRegistFlg" @click="movePage('P_INI_003', iniMasterPageParams)">
          {{ $t("label.lbl_iniMasterRegistrationStatus") }}
        </v-btn>
        <!-- ここから不良・廃棄登録・更新・削除時に使用 -->
        <v-btn v-if="defectiveDiscardedFileUploadFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="defectiveDiscardedFileUploadFlg" @click="movePage('P_WST_001')">
          {{ $t("label.lbl_defectiveDiscardedList") }}
        </v-btn>
        <v-btn v-if="defectiveDiscardedFileUploadFlg" @click="moveDetail">
          {{ $t("label.lbl_defectiveDiscardedDetail") }}
        </v-btn>
        <v-btn v-if="defectiveDiscardedDetailPageFlag" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="defectiveDiscardedDetailPageFlag" @click="movePage('P_WST_001')">
          {{ $t("label.lbl_defectiveDiscardedList") }}
        </v-btn>
        <v-btn v-if="defectiveDiscardedDetailPageFlag" @click="moveDetail">
          {{ $t("label.lbl_defectiveDiscardedDetail") }}
        </v-btn>
        <!-- 号車登録 -->
        <v-btn v-if="shipTruckNumAddPageFlag" @click="refresh()">
          {{ $t("label.lbl_shipTruckNumAdd") }}
        </v-btn>
        <!-- 個別引当 -->
        <v-btn v-if="ResInvFlg" @click="movePage('P_STK_004')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <v-btn v-if="resOkFlg" @click="movePage('P_STK_005')">
          {{ $t("btn.btn_ok_en") }}
        </v-btn>
        <!-- ここからユーザー品番マスタ登録時に使用 -->
        <v-btn v-if="userProductMstFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="userProductMstFlg" @click="movePage('P_SUP_012')">
          {{ $t("label.lbl_userProductMastaList") }}
        </v-btn>
        <v-btn v-if="userProductMstAddFlg" @click="moveDetail">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- 搬入指示一覧画面 -->
        <v-btn v-if="deodpListFlg" @click="movePage('P_SUP_001', deodpListPageParams)">
          {{ $t("btn.btn_list") }}
        </v-btn>
        <!-- 搬入指示登録画面 -->
        <v-btn v-if="deodpAddFlg" @click="movePage('P_SUP_002')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <v-btn v-if="deodpAddPageFlg" @click="deliveryOrderPlanAdd">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- 搬入指示取込画面 -->
        <v-btn v-if="deodpImportFlg" @click="movePage('P_SUP_005')">
          {{ $t("btn.btn_imp") }}
        </v-btn>
        <!-- ここから加工計画登録時に使用 -->
        <v-btn v-if="distProcPlanAddFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="distProcPlanAddFlg" @click="movePage('P_TMP_903')">
          {{ $t("label.lbl_distributionProcessingPlanSearchList") }}
        </v-btn>
        <v-btn v-if="distProcPlanAddFlg" @click="distProcPlanAdd">
          {{ $t("label.lbl_distributionProcessingPlanAdd") }}
        </v-btn>
        <v-btn v-if="distProcPlanEditFlg" @click="movePage('M_HOM')">
          {{ $t("menu.P-MENU-000") }}
        </v-btn>
        <v-btn v-if="distProcPlanEditFlg" @click="movePage('P_TMP_903')">
          {{ $t("label.lbl_distributionProcessingPlanSearchList") }}
        </v-btn>
        <v-btn v-if="distProcPlanEditFlg" @click="movePage('P_TMP_901')">
          {{ $t("label.lbl_distributionProcessingPlanAdd") }}
        </v-btn>
        <v-btn v-if="dstInventoryConfirmFlg" @click="movePage('P_TMP_913')">
          {{ $t("label.lbl_distributionDistributionInventoryConfirmationByProcess") }}
        </v-btn>
        <v-btn v-if="dstMaterialsOrderPointFlg" @click="movePage('P_TMP_912')">
          {{ $t("btn.btn_input") }}
        </v-btn>
        <!-- ここからマスタ初期導入時に使用 -->
        <v-btn v-if="iniClientSelectFlg" @click="movePage('P_INI_001')">
          {{ $t("btn.btn_iniClientList") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

<script>
import { appConfig } from "../assets/scripts/js/AppConfig";
export default {
  name: "messageDialog",

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "確認",
      required: false,
    },
    inputBtnAction: {
      type: Function,
    },
    okAction: {
      type: Function,
      required: false,
    },
    isRequireOkActionWhenpressOkBtn: {
      type: Boolean,
      default: false,
      required: false,
    },
    message: {},
    // OKボタンフラグ
    firstPageFlag: {},
    // 閉じるボタンフラグ
    closeFlag: {},
    // ホームボタンフラグ
    homePageFlag: {},
    // 出荷予定登録画面ボタン
    shpSchAddFlg: {},
    // 出荷予定一覧画面ボタン
    shpSchListFlg: {},
    // 入庫実績登録画面ボタン
    entAchSerchFlg: {},
    // 在庫引当画面ボタン
    InvResFlg: {},
    //在庫引当ボタン（t:在庫引当）
    InvResTransitionFlg: {},
    // 引当結果確認画面ボタン
    resConFlg: {},
    // 画面外を押下してもダイアログを閉じないフラグ（true: 閉じない, false:閉じる）
    outsideClickNotCloseFlg: {},
    // 商品マスタ登録画面
    itemsMstFlg: {},
    // 所属マスタ登録画面
    belongMstFlg: {},
    // 入荷予定一覧画面ボタン
    rcvSchListFlg: {},
    // 入荷予定一覧画面（試作部品）ボタン
    rcvSchHacListFlg: {},
    // 入荷予定データ取込画面ボタン
    rcvDetaImportFlg: {},
    // 加工計画一覧画面ボタン
    dstSchListFlg: {},
    // ロケマスタ登録
    locaMstFlg: {},
    // 棚卸実績承認
    cntapprovalFlg: {},
    // 入荷予定登録画面ボタン
    rcvSchAddFlg: {},
    // 返品伝票承認
    rtnapprovalFlg: {},
    // 返品伝票登録
    rtnAddFlg: {},
    // はい替え指示一覧
    movLocListFlg: {},
    // はい替え実績登録（指示なし）
    movLocAchAddFlg: {},
    // はい替え実績登録（指示あり）
    movLocAchAddInsFlg: {},
    // 予定実績対比
    mngComSchFlg: {},
    // 請求契約単価登録変更
    bilCotractUnitPriceRegistrationFlg: {},
    // 請求実績一覧
    bilActualFlg: {},
    // フォーマット別追加情報入力画面
    shpAddFormatFlg: {},
    // 今後各画面専用のフラグ作成予定（↓削除予定）
    secondPageFlag: {},
    thirdPageFlag: {},
    forthPageFlag: {},
    fivePageFlag: {},
    sixPageFlag: {},
    sevenPageFlag: {},
    relatedMstFlg: {},
    // 在庫情報変更指示
    stockUpdateInstructionFlg: {},
    stockInstructionAddFlg: {},
    // 在庫情報変更データ取込
    stockInformationChangeDataFlg: {},
    // 画面権限一括修正
    roleMasterBatchAddUpdateFlg: {},
    // 不良・廃棄ファイルアップロード
    defectiveDiscardedFileUploadFlg: {},
    // 不良・廃棄明細登録
    defectiveDiscardedDetailPageFlag: {},
    // 号車登録
    shipTruckNumAddPageFlag: {},
    // 個別引当画面ボタン
    ResInvFlg: {},
    resOkFlg: {},
    // ユーザー品番マスタ一覧
    userProductMstFlg: {},
    // ユーザー品番マスタ登録
    userProductMstAddFlg: {},
    //搬入計画一覧
    deodpListFlg: {},
    deodpListPageParams: undefined,
    //搬入計画登録
    deodpAddFlg: {},
    deodpAddPageFlg: {},
    //搬入計画取込
    deodpImportFlg: {},
    //受注一覧
    trnListFlg: {},
    //受注登録
    trnAddFlg: {},
    // ここまで↑

    //棚卸登録
    countInstSearchFlag: {},
    countInstSearchPageParams: undefined,

    // ここから加工計画修正・削除に使用
    distProcPlanAddFlg: {},
    distProcPlanEditFlg: {},
    // ここまで↑

    // ここから加工計画資材発注点登録に使用
    dstInventoryConfirmFlg: {},
    dstMaterialsOrderPointFlg: {},
    // ここまで↑

    // ここからマスタ初期登録に使用
    iniMasterRegistFlg: {},
    iniClientSelectFlg: {},
    iniMasterPageParams: undefined,
    roleMasterParams: undefined,
    // ここまで↑

    // 追加ボタン共通処理
    // 一覧画面へ遷移
    addMoveListPageButtonText: { type: String, default: "" },
    addModeListPage: { type: String, default: "" },
    addModeListPageParams: undefined,
    // 個別画面へ遷移
    addMovePageButtonText: { type: String, default: "" },
    addModePage: { type: String, default: "" },
    addModePageParams: undefined,
  },
  methods: {
    ok(val) {
      // console.debug("this.outsideClickNotCloseFlg");
      if (this.outsideClickNotCloseFlg && val) {
        // 処理なし
      } else {
        this.title = "結果";
        if (this.isRequireOkActionWhenpressOkBtn) {
          this.okAction();
        }
        this.$emit("update:isShow", false);
      }
    },
    close() {
      this.$emit("update:isShow", false);
    },
    no() {
      this.$emit("update:isShow", false);
    },
    movePage(pageNm, param) {
      console.log("movePage(pageNm, param)", pageNm, param);
      console.log("this.$route.name", this.$route.name);
      console.log("appConfig.SCREEN_ID[pageNm]", appConfig.SCREEN_ID[pageNm]);
      if (this.$route.name !== appConfig.SCREEN_ID[pageNm]) {
        // 他画面に遷移する
        this.$router.push({ name: appConfig.SCREEN_ID[pageNm], params: param ?? {} });
      } else if (
        appConfig.SCREEN_ID[pageNm] == appConfig.SCREEN_ID.P_RCV_001 ||
        appConfig.SCREEN_ID[pageNm] == appConfig.SCREEN_ID.P_SHP_001 ||
        appConfig.SCREEN_ID[pageNm] == appConfig.SCREEN_ID.P_STK_001 ||
        appConfig.SCREEN_ID[pageNm] == appConfig.SCREEN_ID.P_SUP_002 ||
        appConfig.SCREEN_ID[pageNm] == appConfig.SCREEN_ID.P_TOD_001
      ) {
        // 入荷予定登録、出荷予定登録（自画面遷移 ※値を引き継ぎ連続登録するためmovePageを使用）
        this.okAction();
      } else {
        // 操作していた画面に戻る
        this.$emit("update:isShow", false);
      }
    },
    /**
     * リフレッシュ
     */
    refresh() {
      this.$router.go(0);
    },
    moveDetail() {
      this.okAction();
      this.$emit("update:isShow", false);
    },
    distProcPlanAdd() {
      this.okAction();
      this.$emit("update:isShow", false);
    },
    deliveryOrderPlanAdd() {
      this.okAction();
      this.$emit("update:isShow", false);
    },
    /**
     * 画面遷移するメソッド。
     * 自画面遷移（再描画）する時にはこっちメソッドを使用する。（movePageだとエラーが出てしまうので。）
     * 別画面遷移の場合はmovePageを使用する。
     * @param {String} menuId メニューID
     */
    goPage(menuId) {
      // 画面遷移する
      this.$router.go({ name: appConfig.MENU_ID[menuId] });
    },
  },
  moveInventoryReservation() {
    this.$emit("colsePopup");
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}

span {
  color: red;
}
</style>
