import Vue from "vue";
import ErrorMessageDialog from "@/components/ErrorMessageDialog";

export default {
  components: {
    ErrorMessageDialog,
  },
  props: {},
  data: () => ({
    errorMessage: Vue.observable({
      isError: false,
      message: "",
    }),
  }),
  provide() {
    return {
      errorMessage: this.errorMessage,
    };
  },
  methods: {},
};
