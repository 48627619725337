import { appConfig } from "@/assets/scripts/js/AppConfig";
import { i18n } from "@/lang/lang.js";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
import chardet from "chardet";
import errorMessageMixin from "@/mixins/ErrorMessageMixin";
import loadingSpinnerMixin from "@/mixins/LoadingSpinnerMixin";
import Loading from "@/components/loading";
import NavBar from "@/components/NavBar.vue";
import SideMenu from "@/components/Menu";
import CDatePicker from "@/components/CDatePicker";
import CClientInput from "@/components/CClientInput";
import CArrivalInput from "@/components/CArrivalInput";
import CFileInput from "@/components/CFileInput";
import CItemInput from "@/components/CItemInput";
import CItemInputRegistable from "@/components/CItemInputRegistable";
import CCodeInput from "@/components/CCodeInput";
import CWarehouseInput from "@/components/CWarehouseInput";
import CLocationInput from "@/components/CLocationInput";
import CDateRangePicker from "@/components/CDateRangePicker";
import CBelongInput from "@/components/CBelongInput";
import CUserInput from "@/components/CUserInput";
import CTableColumnTooltip from "@/components/CTableColumnTooltip";
import CCarrierCompInput from "@/components/CCarrierCompInput";
import CShipToInput from "@/components/CShipToInput";

export default {
  mixins: [errorMessageMixin, loadingSpinnerMixin],
  components: {
    Loading,
    NavBar,
    SideMenu,
    CClientInput,
    CArrivalInput,
    CDatePicker,
    CFileInput,
    CItemInput,
    CItemInputRegistable,
    CCodeInput,
    CWarehouseInput,
    CDateRangePicker,
    CLocationInput,
    CBelongInput,
    CUserInput,
    CTableColumnTooltip,
    CCarrierCompInput,
    CShipToInput,
  },
  data: () => ({
    // 現在の検索条件
    searchFilter: {},
    // 前画面の検索条件
    previousSearchFilter: {},
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      screenFlag: false,
      redMessage: "",
      title: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      changeFlag: false,
      homePageFlag: false,
      firstPageFlag: false,
      rcvSchListFlg: false,
      rcvSchAddFlg: false,
      shpSchListFlg: false,
      shpSchAddFlg: false,
      itemsMstFlg: false,
      belongMstFlg: false,
      outsideClickNotCloseFlg: false,
      sevenPageFlag: false,
      locaMstFlgg: false,
      simpleDialog: false,
      shipTruckNumAddPageFlag: false,
    },
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
      formatAmount(value) {
        if (!value) return "";
        console.log(
          new Intl.NumberFormat("ja-JP", {
            style: "decimal",
            currency: "JPY",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value)
        );
        return new Intl.NumberFormat("ja-JP", {
          style: "decimal",
          currency: "JPY",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value);
      },
      extractNumeric(value) {
        return value.replace(/\D/g, "");
      },
      setNestedProperty(context, path, value) {
        const keys = path.split(".");
        let current = context;
        keys.slice(0, -1).forEach((key) => {
          current = current[key] = current[key] || {};
        });
        current[keys[keys.length - 1]] = value;
      },
      amountHandleInput(context, value, formattedTarget, numericTarget) {
        const numericValue = this.extractNumeric(value);
        this.setNestedProperty(context, numericTarget, numericValue);
        this.setNestedProperty(context, formattedTarget, this.formatAmount(numericValue));
      },
    },
  }),
  methods: {
    importCSV(importFile, isQuateIdentify = false) {
      return new Promise((resolve, reject) => {
        // 選択したファイルを読み込む
        const csvFile = importFile;

        // 拡張子チェック
        let fileType = csvFile.type;
        if (
          fileType != "text/csv" &&
          ".csv" !== csvFile.name.substring(csvFile.name.lastIndexOf(".")).toLowerCase()
        ) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileType");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }

        // ファイル名の桁数をチェック
        if (csvFile.name.substring(0, csvFile.name.indexOf(".")).length > 31) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileName");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }

        // サイズチェック
        if (csvFile.size <= 0) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMin");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          return;
        }
        if (csvFile.size >= 3000000) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMax");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          return;
        }

        const reader = new FileReader();
        reader.readAsArrayBuffer(csvFile);

        // 読込完了したら、次の処理に進むよう設定
        reader.onload = (event) => {
          const uint8Array = new Uint8Array(event.target.result);

          // 文字コードチェック
          const analyseEncoding = chardet.analyse(uint8Array);
          console.log(analyseEncoding);

          const relevantEncodings = ["shift_jis", "utf-8"];
          const filteredEncodings = analyseEncoding.filter((encoding) => {
            return relevantEncodings.includes(encoding.name.toLowerCase());
          });

          let matchEncoding = appConfig.CONSTANT.CHARSET.UTF_8;
          if (filteredEncodings.length > 0) {
            matchEncoding = filteredEncodings.reduce((prev, current) => {
              return prev.confidence > current.confidence ? prev : current;
            }).name;
          }

          // BOMチェック
          let offset = 0;
          if (
            uint8Array.length >= 3 &&
            uint8Array[0] === 0xef &&
            uint8Array[1] === 0xbb &&
            uint8Array[2] === 0xbf
          ) {
            offset = 3;
          }

          // データ変換
          let decodeText;
          if (matchEncoding.toLowerCase() == appConfig.CONSTANT.CHARSET.SHIFT_JIS) {
            decodeText = new TextDecoder(appConfig.CONSTANT.CHARSET.SHIFT_JIS).decode(uint8Array);
          } else {
            decodeText = new TextDecoder(appConfig.CONSTANT.CHARSET.UTF_8).decode(
              uint8Array.subarray(offset)
            );
          }

          // 改行毎にデータを分ける
          const lines = decodeText
            // CR,CRLF,LF改行処理
            .split(/\r\n|\n|\r/g)
            .filter((x) => x !== "")
            .filter((x) => x !== "\r");

          let changeArrangement = {};
          var inListCsvDataori = [];

          // CSVから読み取ったデータを配列に編集
          for (var i = 0; i < lines.length; i++) {
            // カンマで区切り、値を取りだす
            changeArrangement = isQuateIdentify
              ? this.csvSplit(lines[i])
              : lines[i].replace(/"/g, "").split(",");
            console.log(changeArrangement);
            inListCsvDataori.push(changeArrangement);
          }

          let column = inListCsvDataori[0].length;
          console.log("t : " + column);
          for (var j = 0; j < inListCsvDataori.length; j++) {
            console.log(inListCsvDataori[j].length);
            // 1行ずつのカラム数をチェック
            if (inListCsvDataori[j].length != column) {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_009_E", [j + 1]);
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              return;
            }
          }
          resolve(inListCsvDataori);
        };
        // すでに取込したCSVファイルを変更してそのまま取込した場合
        reader.onerror = (e) => {
          console.log("reader.error: " + reader.error);
          if (e.target.error != null) {
            this.infoDialog.message = i18n.tc("check.chk_reSelectCsv");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.importfile = null;
          }
          reject();
        };
      });
    },
    importTxt(importFile) {
      return new Promise((resolve, reject) => {
        // 選択したファイルを読み込む
        const txtFile = importFile;

        // .flat等のファイルの読み取りにも使用するため拡張子チェックはしない

        // ファイル名の桁数をチェック
        if (txtFile.name.substring(0, txtFile.name.indexOf(".")).length > 31) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileName");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }

        // サイズチェック
        if (txtFile.size <= 0) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMin");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          return;
        }
        if (txtFile.size >= 3000000) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMax");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          return;
        }

        const reader = new FileReader();
        reader.readAsArrayBuffer(txtFile);

        // 読込完了したら、次の処理に進むよう設定
        reader.onload = (event) => {
          const uint8Array = new Uint8Array(event.target.result);

          // 文字コードチェック
          var detectedEncoding = chardet.detect(uint8Array);

          // BOMチェック
          let offset = 0;
          if (
            uint8Array.length >= 3 &&
            uint8Array[0] === 0xef &&
            uint8Array[1] === 0xbb &&
            uint8Array[2] === 0xbf
          ) {
            offset = 3;
          }

          // データ変換
          var decodeText;
          if (detectedEncoding.toLowerCase() == appConfig.CONSTANT.CHARSET.SHIFT_JIS) {
            decodeText = new TextDecoder(appConfig.CONSTANT.CHARSET.SHIFT_JIS).decode(uint8Array);
          } else {
            decodeText = new TextDecoder(appConfig.CONSTANT.CHARSET.UTF_8).decode(
              uint8Array.subarray(offset)
            );
          }

          // 改行毎にデータを分ける
          const lines = decodeText
            // CR,CRLF,LF改行処理
            .split(/\r\n|\n|\r/g)
            .filter((x) => x !== "")
            .filter((x) => x !== "\r");

          let changeArrangement = {};
          var inListCsvDataori = [];

          // CSVから読み取ったデータを配列に編集
          for (var i = 0; i < lines.length; i++) {
            // カンマで区切り、値を取りだす
            changeArrangement = [lines[i].replace(/"/g, "")];
            inListCsvDataori.push(changeArrangement);
          }

          let column = inListCsvDataori[0].length;
          console.log("t : " + column);
          for (var j = 0; j < inListCsvDataori.length; j++) {
            console.log(inListCsvDataori[j].length);
            // 1行ずつのカラム数をチェック
            if (inListCsvDataori[j].length != column) {
              this.infoDialog.message = i18n.tc("check.chk_inputColumn");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              return;
            }
          }
          resolve(inListCsvDataori);
        };
        // すでに取込したファイルを変更してそのまま取込した場合
        reader.onerror = (e) => {
          console.log("reader.error: " + reader.error);
          if (e.target.error != null) {
            this.infoDialog.message = i18n.tc("check.chk_reSelectFile");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.importfile = null;
          }
          reject();
        };
      });
    },
    /**
     * CSV列内のカンマを識別した上で分割する
     *
     * @param {string} line 文字列
     * @return {Array<string>} 配列
     */
    csvSplit(line) {
      var c = "";
      var s = "";
      var data = [];
      var isQuote = false;

      for (var i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (i == line.length - 1) {
          if (c == ",") {
            // 最後の要素がカンマの場合、カンマの後ろの空文字も列として追加
            data.push(s);
            data.push(s);
          } else {
            if (c == '"') {
              data.push(s);
            } else {
              data.push((s + c).toString());
            }
          }
        } else if (c == "," && !isQuote) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && isQuote) {
          s = s + c;
        } else if (c == '"') {
          isQuote = !isQuote;
        } else {
          s = s + c;
        }
      }
      return data;
    },
  },
};
