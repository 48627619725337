<template>
  <div class="d-flex">
    <div class="d-flex" :style="width ? { width: width } : {}">
      <v-file-input
        v-model="importfile"
        :label="$t('label.lbl_importFile')"
        :rules="required ? [vRules.fileRequired, ...rules] : [, ...rules]"
        prepend-icon=""
        append-icon=""
        append-outer-icon="mdi-paperclip"
        @click:append-outer="handleClickAppendOuter"
        :disabled="disabled"
        :accept="accept"
        ref="vFileInput"
        :counter="true"
        outlined
        dense
      >
      </v-file-input>
      <span class="require asterisk-spacer" v-if="required">*</span>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";

export default {
  inheritAttrs: false,
  props: {
    // 値
    value: {
      type: [File, Object],
      default: () => ({}),
    },
    // 必須
    required: {
      type: Boolean,
      default: true,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // 拡張子
    accept: {
      type: String,
      default: "*",
    },
    // 幅
    width: {
      type: String,
      default: undefined,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    // 選択ファイル
    importfile: {},
    // バリデーション
    vRules: {
      fileRequired: (value) => (!!value && !!value.name) || i18n.tc("check.chk_select"),
    },
  }),
  methods: {
    // クリップアイコンボタン押下、エクスプローラーを開く
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
  },
  watch: {
    value(newValue) {
      this.importfile = newValue;
    },
    importfile(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.api-btn ::v-deep {
  margin: 0 0 0 12px;
  width: 7rem;
}
</style>
