<template>
  <div>
    <v-tooltip :disabled="!isTooltipFlg" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="ellipsis-tooltip"
          v-bind="attrs"
          v-on="on"
          :style="{ maxWidth: maxWidth + 'px' }"
        >
          <template v-if="Array.isArray(value)">
            <div ref="textElements" class="ellipsis" v-for="(line, index) in value" :key="index">
              {{ line }}
            </div>
          </template>
          <template v-else>
            <div ref="textElement" class="ellipsis">{{ value }}</div>
          </template>
        </div>
      </template>
      <div>
        <template v-if="Array.isArray(value)">
          <div v-for="(line, index) in value" :key="index">{{ line }}</div>
        </template>
        <template v-else>
          {{ value }}
        </template>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: () => "",
    },
    maxWidth: {
      type: Number,
      default: 175,
    },
  },
  data() {
    return {
      // ツールチップフラグ
      isTooltipFlg: false,
    };
  },
  methods: {
    checkOverflow() {
      if (Array.isArray(this.value)) {
        this.isTooltipFlg = this.$refs.textElements.some(
          (element) => element.scrollWidth > element.clientWidth
        );
      } else {
        const element = this.$refs.textElement;
        if (element) {
          this.isTooltipFlg = element.scrollWidth > element.clientWidth;
        }
      }
    },
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.checkOverflow();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkOverflow();
    });
  },
};
</script>

<style scoped>
.ellipsis-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
